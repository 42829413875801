import React, { useState } from 'react';
import { useData } from '../../../contexts/DataContext';
import TextInput from "./Textinput";
import Button from "../button/Button";
import { notifyError, notifySuccess } from "../Toastify";
import Stack from '../../box/container/Stack';

export default function CreateTaskTemplate(props) {
  const { auditTemplateId, onClose } = props;
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');

  const { createAnTaskTemplate, refresh, setRefresh } = useData();

  async function handleTaskTemplateCreation() {
    try {
      if (!name || !number) {
        notifyError('Veuillez remplir tous les champs');
        return;
      }
  
      await createAnTaskTemplate(name, number, auditTemplateId);
  
      notifySuccess('Template de tâche créé');
      setRefresh(!refresh);
      onClose();
    } catch (error) {
      notifyError('Erreur lors de la création du template de tâche');
      console.error('Erreur lors de la création du template de tâche :', error);
    }
  }
  

  return (
    <Stack direction={"column"} spacing={"15px"}>
      <Stack direction={"column"}>
        <TextInput
          type="text"
          label="Nom"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          variant={"blue"}
        />
        <TextInput
          type="number"
          label="Jours avant la date finale"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          required
          variant="blue"
        />
      </Stack>
      <Button width="full-width" variant={"primary"} onClick={handleTaskTemplateCreation}>
        Créer
      </Button>
    </Stack>
  );
}
