import MenuItem from '../ui/aside-section/HeaderItems';
import { CiViewList } from 'react-icons/ci';
import { PiUsersThin } from "react-icons/pi";
import Accordion from '../ui/Accordion';


function MainMenu() {
    return (
        <Accordion title="Espace de travail" defaultOpen={false} highlight={false} fontSize='sm' variant='compact'>
            <ul className="menu">
                <MenuItem text="Clients" icon={PiUsersThin} variant="classique" to="/clients" />
                <MenuItem text="Templates" icon={CiViewList} variant="classique" to="/audit-templates" />
            </ul>
        </Accordion>
    );
}

export default MainMenu;
