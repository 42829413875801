function dateConverter(date) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    
    const dateISO = new Date(date);
    let dateFormatee = dateISO.toLocaleDateString('fr-FR', options);

    if (dateISO.getDate() === 1) {
        dateFormatee = dateFormatee.replace('1', '1er');
    }

    return dateFormatee;
}

export { dateConverter };