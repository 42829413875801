import React, { useState, useEffect } from 'react';
import styled, { css } from "styled-components";
import { MdKeyboardArrowRight } from "react-icons/md";
import Stack from "../box/container/Stack";

const paddingAndBorderRadiusStyles = css`
    border-radius: ${(props) =>
        props.$variant === "compact" ? "5px" : "10px"};
    padding: ${(props) => {
        if (props.$variant === "compact") {
            return props.$isOpen ? "12px 18px 12px 8px" : "12px 18px";
        }
        return props.$isOpen ? "20px" : "10px 16px";
    }};
`;

const AccordionContainer = styled.div`
    width: 100%;
    color: var(--color-title);
    overflow: hidden;
    background: ${props => (props.$highlight ? "var(--secondary-bg-color)" : "")};
    position: relative;
`;

const AccordionHeader = styled.div`
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    display: flex;
    gap: 10px;
    transition: 0.3s;
    position: relative;

    ${paddingAndBorderRadiusStyles}

    &:hover {
        background: var(--nav-bg-active);
    }
`;

const AccordionWrapper = styled.div`
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    ${({ $isOpen, $contentHeight }) =>
        $isOpen
            ? css`
                height: ${$contentHeight}px;
            `
            : css`
                height: 0;
            `}
`;

const AccordionContent = styled.div``;

const AccordionChip = styled.span`
line-height: 0;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    gap: 5px;
    position: relative;
    display: flex;
    color: var(--success-color);
    background: var(--success-bg);
    border: none;
    padding: 5px;
    font-size: 15px;
    border-radius: 50px;
    margin: 0;
`;

const IconContainer = styled.div`
    transform: rotate(${props => props.$isOpen ? '90deg' : '0deg'});
    transition: transform 0.3s ease;
    line-height: 0;
`;

const TextAccordion = styled.p`
display: flex;
align-items: center;
gap: 10px;
  color: ${(props) => {
        if (props.$isOpen || props.$isHovered) {
            return "var(--color-title)";
        }
        return props.$variant === "default"
            ? "var(--paragraph)"
            : props.$variant === "colored"
                ? "var(--main-color)"
                : props.$variant === "white"
                    ? "#ADADB0"
                    : "var(--paragraph)";
    }};
  font-size: ${(props) => {
        switch (props.$size) {
            case "sm":
                return "15px";
            case "md":
                return "18px";
            case "lg":
                return "20px";
            default:
                return "18px";
        }
    }};
`;

export default function Accordion({ children, title, chipValue, defaultOpen = false, highlight = true, size = "md", variant = "default" }) {
    const [isOpen, setIsOpen] = useState(defaultOpen || false);
    const [isHovered, setIsHovered] = useState(false);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (defaultOpen)
            setHeight('auto');
    }, [defaultOpen]);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <AccordionContainer $highlight={highlight} $chipValue={chipValue}>
            <AccordionHeader
                onClick={toggleAccordion}
                $isOpen={isOpen}
                $variant={variant}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <TextAccordion $isOpen={isOpen} $isHovered={isHovered} $size={size}>
                    {title}
                </TextAccordion>
                <Stack align={"center"} justify={"center"} spacing={"5px"}>
                    {chipValue &&
                        <AccordionChip>
                            {chipValue}
                        </AccordionChip>
                    }
                    <IconContainer $isOpen={isOpen}>
                        <MdKeyboardArrowRight size={24} />
                    </IconContainer>
                </Stack>
            </AccordionHeader>
            <AccordionWrapper $isOpen={isOpen} $contentHeight={height}>
                {isOpen && (
                    <AccordionContent ref={(content) => {
                        if (content) {
                            setHeight(content.scrollHeight);
                        }
                    }}>
                        {children}
                    </AccordionContent>
                )}
            </AccordionWrapper>
        </AccordionContainer>
    );
}
