import { getUrl } from 'aws-amplify/storage';

const fetchS3File = async (pictureKey) => {
    try {
        if (pictureKey) {
            const url = await getUrl({
                key: pictureKey,
                options: {
                    accessLevel: 'guest',
                    expiresIn: 3600
                }
            });
            return url.url;
        }
    } catch (error) {
        console.error('Error fetching file from S3:', error);
        throw error;
    }
};

export default fetchS3File;