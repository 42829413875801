import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

function SmallCircleProgress({ progress, label = 'Progress Bar', showDecimals = true }) {
  const textRef = useRef(null);
  const [circleSize, setCircleSize] = useState(0);
  const [strokeWidth, setStrokeWidth] = useState(6);

  useEffect(() => {
    if (textRef.current) {
      const textHeight = textRef.current.offsetHeight;
      setCircleSize(textHeight);
      setStrokeWidth(Math.max(2, textHeight * 0.1));
    }
  }, [progress, showDecimals]);

  const radius = (circleSize / 2) - (strokeWidth * 2);
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (progress / 100) * circumference;

  const getColor = () => {
    if (progress < 40) {
      return 'var(--error-color)';
    } else if (progress >= 40 && progress < 75) {
      return 'var(--warning-color)';
    } else {
      return 'var(--success-color)';
    }
  };

  const displayedProgress = showDecimals ? progress : Math.round(progress);

  return (
    <Container>
      <svg
        aria-label={label}
        aria-valuemax={100}
        aria-valuemin={0}
        aria-valuenow={displayedProgress}
        height={circleSize}
        width={circleSize}
        viewBox={`0 0 ${circleSize} ${circleSize}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Circle
          cx={circleSize / 2}
          cy={circleSize / 2}
          r={radius}
          strokeWidth={strokeWidth}
        />

        <FilledCircle
          cx={circleSize / 2}
          cy={circleSize / 2}
          data-testid="progress-bar-bar"
          r={radius}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
          strokeWidth={strokeWidth}
          color={getColor()}
        />
      </svg>
      <PercentageText ref={textRef} color={getColor()}>
        {displayedProgress}%
      </PercentageText>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Circle = styled.circle`
  fill: var(--secondary-bg-color);
  stroke: var(--bg-color);
  stroke-linecap: round;
`;

const FilledCircle = styled(Circle)`
  stroke: ${(props) => props.color};
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 0.5s ease-out;
`;

const PercentageText = styled.span`
  color: ${(props) => props.color};
  margin-left: 2px;
`;

export default SmallCircleProgress;
