import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import IconButton from "./ui/button/IconButton";
import { CiTrash } from "react-icons/ci";
import Title from "./ui/textual/Title";
import Text from "./ui/textual/Text";
import { useData } from "../contexts/DataContext";
import Bento from "./box/bento/Bento";
import { confirm } from "../components/confirm/ConfirmGlobal";
import { generateClient } from "aws-amplify/api";
import {
  notifySuccess,
  notifyError,
} from '../components/ui/Toastify';
import Pastille from "./ui/textual/Pastille";
import Checkbox from "./ui/checkbox/CheckboxItem";
import Table from './ui/table/Table';
import Th from './ui/table/Th';
import Td from './ui/table/Td';
import { useUser } from '../contexts/UserContext';
import Stack from './box/container/Stack';
import { IoMdAdd } from 'react-icons/io';
import AnimationComponent from './animation/Animation';
import Empty from './animation/storageAnimation/empty.json';
import { dateConverter } from '../tools/dateConverter';
import {
  deleteTasksTemplate
} from "../graphql/mutations";
import { handlerVariantTaskTemplateDaysBefore } from '../tools/handlerVariantTaskTemplateDaysBefore';
import Accordion from './ui/Accordion'
import Tips from './ui/textual/Tips';
import { usePopup } from '../contexts/PopupContext';
import AddTaskTemplatePopup from './ui/popup/allPopups/AddTaskTemplatePopup';

const client = generateClient();

const DivTrash = styled.div`
    display: flex;
    margin: -20px 20px;
    justify-content: flex-end;
    width: 100%;
`;

export default function AuditCardTemplate({ audit }) {
  const { popups, openPopup, closePopup } = usePopup();
  const [tasks, setTasks] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [allChecked, setAllChecked] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [deleteButtonState, setDeleteButtonState] = useState("notactive");

  const {
    deleteAnAuditTemplate,
    deleteAnTaskTemplate,
    refresh,
    setRefresh
  } = useData();

  const { isAdmin } = useUser();

  useEffect(() => {
    if (audit) {
      audit.tasksTemplates.items.sort((a, b) => a.daysBeforeTargetDay - b.daysBeforeTargetDay);
      setTasks(audit.tasksTemplates.items);
    }
  }, [audit, refresh]);


  useEffect(() => {
    if (selectedCount > 0) {
      setDeleteButtonState("active");
    } else {
      setDeleteButtonState("notactive");
    }
  }, [selectedCount]);

  useEffect(() => {
    const count = Object.values(checkedItems).filter(value => value).length;
    setSelectedCount(count);
    setAllChecked(count === tasks?.length);
  }, [checkedItems, tasks]);


  const handleChildCheckboxChange = (taskTemplateId) => {
    const updatedCheckedItems = { ...checkedItems, [taskTemplateId]: !checkedItems[taskTemplateId] };
    setCheckedItems(updatedCheckedItems);
  };

  const handleParentCheckboxChange = () => {
    const updatedCheckedItems = {};
    if (!allChecked) {
      tasks.forEach((item) => {
        updatedCheckedItems[item.id] = true;
      });
    }
    setCheckedItems(updatedCheckedItems);
    setAllChecked(!allChecked);
  };

  const deleteCheckedTasksTemplates = async () => {
    const checkedtaskTemplateIds = Object.keys(checkedItems).filter(taskTemplateId => checkedItems[taskTemplateId]);
    try {
      if (await confirm({
        title: "Voulez-vous vraiment supprimer ces tâches ?",
        content: "Ces tâches seront supprimées définitivement et personne ne pourra y accéder",
        variant: "danger"
      })) {
        await Promise.all(checkedtaskTemplateIds.map(async taskTemplateId => {
          await deleteTaskTemplate(taskTemplateId);
        }));
        setRefresh(!refresh);
        notifySuccess(`${checkedtaskTemplateIds.length} tâches supprimées avec succès`);
        setCheckedItems({});
      }
    } catch (error) {
      console.error("Erreur lors de la suppression des tâches :", error);
      notifyError("Erreur lors de la suppression des tâches");
    }
  };


  async function deleteTaskTemplate(id) {
    try {
      await client.graphql({
        query: deleteTasksTemplate,
        variables: {
          input: {
            id: id
          }
        }
      });
    } catch (error) {
      console.error("Erreur lors de la suppression du template de tâche :", error);
    }
  }  

  return (
    <Accordion title={audit.name}>
      <Stack direction="column">
        <Bento highlight="highlight" overflow="visible">
          {isAdmin && (
            <DivTrash>
              <IconButton variant="danger" onClick={async () => {
                try {
                  await deleteAnAuditTemplate(audit.id);
                  setRefresh((prev) => !prev);
                } catch (error) {
                  console.error('Erreur lors de la suppression :', error);
                }
              }}>
                <CiTrash />
              </IconButton>
            </DivTrash>

          )}
          <Title level={3}>{audit.name}</Title>
          <Text>{`Créé le ${dateConverter(audit.createdAt)}`}</Text>
          <Stack>
            <Pastille variant="success">{audit.type}</Pastille>
          </Stack>
          {isAdmin && (
            <Stack justify="end">
              <IconButton variant="danger" onClick={deleteCheckedTasksTemplates} enable={deleteButtonState}>
                <CiTrash /> Supprimer ({selectedCount})
              </IconButton>
              <IconButton variant="action" onClick={() => openPopup(audit.id)}>
                <IoMdAdd /> Ajouter une Tâche
              </IconButton>
            </Stack>
          )}
          {tasks && tasks.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  {isAdmin && (
                    <Th variant="highlight">
                      <Checkbox
                        type="checkbox"
                        checked={allChecked}
                        onChange={handleParentCheckboxChange}
                      />
                    </Th>
                  )}
                  <Th variant="highlight">Tâche</Th>
                  <Th variant="highlight">Délai</Th>
                  {isAdmin && (
                    <Th variant="highlight">Actions</Th>
                  )}
                </tr>
              </thead>
              <tbody>
                {tasks && tasks.map((item, index) => (
                  <tr key={item.id}>
                    {isAdmin && (
                      <Td>
                        <Checkbox
                          type="checkbox"
                          checked={checkedItems[item.id] || false}
                          onChange={() => handleChildCheckboxChange(item.id)}
                        />
                      </Td>
                    )}
                    <Td>{item.name}</Td>
                    <Td>
                      <Pastille variant={handlerVariantTaskTemplateDaysBefore(item.daysBeforeTargetDay)}>
                        {item.daysBeforeTargetDay}j avant l'audit
                      </Pastille>
                    </Td>
                    {isAdmin && (
                      <Td>
                        <IconButton variant="danger" wtext="no" onClick={async () => {
                          await deleteAnTaskTemplate(item.id);
                          setRefresh(!refresh);
                        }}>
                          <CiTrash />
                        </IconButton>
                      </Td>

                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Stack direction="column" align="center">
              <AnimationComponent animationData={Empty} width="150px" />
              <Text>
                Cet audit n'a aucune tâche
              </Text>
              {isAdmin ? (
                <IconButton variant="action" onClick={() => openPopup(audit.id)}>
                  <IoMdAdd /> Ajouter une tâche
                </IconButton>
              ) : (
                <Tips variant={"warning"}>Vous n'avez pas les droits pour ajouter une tâche, contactez un admin</Tips>
              )}
            </Stack>
          )}
        </Bento>
        <AddTaskTemplatePopup
          open={popups[audit.id]}
          onClose={() => closePopup(audit.id)}
          auditTemplateId={audit.id}
        />
      </Stack>
    </Accordion>
  )
}
